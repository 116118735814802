import { userAxios } from '../index'

const checkProcedure = async (data) => {
    try {
        return await userAxios.post(`officeVisits/okProcedure`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const adjustTotal = async (data,id) => {
    try {
        return await userAxios.post(`officeVisits/adjust-total/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}


export default {
    checkProcedure,
    adjustTotal
}
